.testimonials-evet {
    color:#5e6c84;
  }
  
  .testimonials-evet p {
    color:#5e6c84;
  }
  
  .testimonials-evet h2 {
    font-weight:bold;
    margin-bottom:40px;
    padding-top:40px;
    color:inherit;
  }
  
  @media (max-width:767px) {
    .testimonials-evet h2 {
      margin-bottom:25px;
      padding-top:25px;
      font-size:24px;
    }

  }

  /* mobile view */
@media only screen and (max-width:992px){

  .testimonials-evet .item .box {
    border-radius: 10px;
     margin: 0 !important;
    background: #e5e9ff;
    box-shadow: 0 10px 29px 0 rgb(68 88 144 / 10%);
    transition: all 0.3s ease-in-out;
    text-align: center;
    padding: 10px !important;
    background-color: #e5e9ff;
    position: relative;
}
}
  
  .testimonials-evet .intro {
    font-size:16px;
    max-width:500px;
    margin:0 auto;
  }
  
  .testimonials-evet .intro p {
    margin-bottom:0;
  }
  
  .testimonials-evet .people {
    padding:50px 0 20px;
  }
  
  .testimonials-evet .item {
    margin-bottom:32px;
  }
  .testimonials-evet .item:hover {
    filter: brightness(0.95) !important;
}
  
  @media (min-width:768px) {
    .testimonials-evet .item {
      height:auto;
    }
  }
  
  .testimonials-evet .item .box {
    border-radius: 10px;
    margin: 15px 20px 15px 20px;
    background: #e5e9ff;
    box-shadow: 0 10px 29px 0 rgb(68 88 144 / 10%);
    transition: all 0.3s ease-in-out;
    text-align: center;
    padding:40px;
    background-color:#e5e9ff;
    position:relative;
  }
  
  .testimonials-evet .item .box:after {
    content:'';
    position:absolute;
    left:30px;
    bottom:-24px;
    width:0;
    height:0;
    border:15px solid transparent;
    border-width:12px 15px;
    border-top-color:#e5e9ff;
  }
  
  .testimonials-evet .item .author {
    margin-top:28px;
    padding-left:25px;
  }
  
  .testimonials-evet .item .name {
    font-weight:bold;
    margin-bottom:2px;
    color:inherit;
  }
  
  .testimonials-evet .item .title {
    font-size:13px;
    color:#9da9ae;
  }
  
  .testimonials-evet .item .description {
    font-size:15px;
    margin-bottom:0;
  }
  
  .testimonials-evet .item img {
    max-width:40px;
    float:left;
    margin-right:12px;
    margin-top:-5px;
  }