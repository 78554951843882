/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

/* custom colors  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;


}

.container{
  overflow-x:hidden;
  overflow-y:hidden;
}


:root {
  --bgcolor: #f1f5fd;
  --primary-color: #24292d;
  --button-color: #066ac9;
  /* --text-color: #747579; */
  --text-color: #5e6c84;
  --main-text-color: #333333;
  
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: #066ac9;
}

h1,
h2,
h3,
h4,
h6 {
  color: #24292d;
  
}

h5{
  color: #fff;
}

/* --------------------------------- navbar css style starts 
                        -------------------------------------------------------*/

.navbar-bg {
  background-color: var(--bgcolor);
  padding: 1rem 0;
  z-index: 99;
  position: relative;
}

.navbar-brand {
  font-size: 2.5rem;
}

.navbar-brand img {
  width: 170px;
}

.navbar li {
  padding: 0 2rem;
}

.navbar li a {
  font-size: 1.7rem;
  font-weight: lighter;
  position: relative;
}

.navbar li a:hover {
  color: var(--primary-color) ;
  text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
}

.btn-style {
  color: #ffffff !important;
  border-radius: 6px;
  background-color: #066ac9;
  border: none;
  font-weight: 500;
  outline: none;
  font-size: 15px;
  padding: 7px 22px;  
  line-height: 30px !important;
}
.nav-item .nav-link .btn-style a:hover {
  color: #ffffff !important;

}


/* --------------------------------- Navbar ends  
                        -------------------------------------------------------*/

/* --------------------------------- Hero sEction  starts  
                        -------------------------------------------------------*/
header {
  background-color: var(--bgcolor);
 
  width: 100%;
}

.main-hero-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding-top: 100px;
  height: 100vh;
}


.main-hero-container h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  /* text-transform: uppercase; */
  text-transform: capitalize;
  font-size: 2.8125rem;
  text-align: center;
  color: var(--primary-color);
  line-height: 1.25;
  color: #24292d;
}



.main-hero-title
{
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  font-size: 3.8125rem !important;
  text-align: left;
  color: var(--primary-color);
  line-height: 1.25;
  color: #24292d;
}

.main-hero-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  font-weight: lighter;
  text-align: center;
}
.fixed-ratio-resize {
  max-width: 100%;
  height: auto;
  width: auto\9;
  margin: 0 0 1rem 0;
  height: 100%;
  text-align: center;
  }

  .probootstrap-section
  {
    padding: 5em 0;
    position: relative;
  }

/* techdesk container */

.techdesk-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 40px;
}
 .techdesk-container h2
 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 2.8125rem;
  color: var(--primary-color);
  text-align: left;
 }
 .techdesk-container h1
 {
  color: var(--primary-color);
  text-align: left;
  margin-bottom: 0.5rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* text-transform: capitalize; */
  font-size: 4.6rem;
 }


 .techdesk-para{
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  font-weight: lighter;
  text-align: left;
 }

 .header1
 {
  background: linear-gradient(180deg, rgba(241,245,253,1) 0%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 67%, rgba(241,245,253,1) 100%);
 }

 .header2
 {
  background-color: var(--bgcolor) !important;

 

 }


 .about-bg{
  background-image: url('../public/images/mask1.png');
  min-height:100%;
  
  background-size:cover;
}

.mission-bg
{
  background-image: url('../public/images/mask2.svg');
  min-height:100%;
  
  background-size:cover;
}
 .subtitle
 {
  font-size: 1.5rem;
  color: var(--text-color);
  /* margin: 3rem 0 3rem 0; */
  font-weight:500;
  text-align: left;
  text-transform:uppercase;
 }

 .fixed-bg{
  background-color: var(--bgcolor);
 }

 /* --------------------------------- Footer  Section starts  
                        -------------------------------------------------------*/

footer {
  padding: 1rem 0;
  background-color: #1e295d;
  color: #fff;
}

footer h2 {
  color: #fff;
}

footer li a{
  list-style: none;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 1rem 0;
  text-decoration: none;
}

.footer-para {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 0 0 0;
  font-weight: lighter;
}

footer li:hover {
  color: var(--bgcolor);
}

footer .fontawesome-style {
  font-size: 2.3rem;
  color: #fff;
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
  color: #f1f5fd;
}

/* --------------------------------- Footer  Section ends  
                        -------------------------------------------------------*/

.clients
{
  margin: 3rem 0 3rem 0;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.h-400px {
  height: 400px !important;
}

.img{
  width: 80%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.sticky-nav {
  position: sticky;
  top: 0;

}


  /* lineloader */

  .linePreloader{
    width:20%;
    height:4px;
    /* background: url("../../../public/images/cargo.png") no-repeat center; */
    background:linear-gradient(to right,#066ac9,#066ac9,#066ac9);
    /* background-color:#e5e9ff; */
    background-color:#066ac9;
    border-radius:4px;
    background-size:2%;
    background-repeat:repeat-y;
    background-position:-25% 0;
    /* animation:scroll 2.5s ease-out infinite;  */
    margin-top: 10px;
  }
  
  @keyframes scroll{
    0%{background-size:10%}
    5%{background-size:10%}
    20%{background-size:10%}
    40%{background-size:10%}
    60%{background-size:10%}
    80%{background-size:10%}
    100%{background-size:10%}
    100%{background-position:125%,0;}
  }


  .linePreloader1{
    width:10%;
    height:4px;
    /* background: url("../../../public/images/cargo.png") no-repeat center; */
    background:linear-gradient(to right,#066ac9,#066ac9,#066ac9);
    /* background-color:#e5e9ff; */
    background-color:#066ac9;
    border-radius:4px;
    background-size:2%;
    background-repeat:repeat-y;
    background-position:-25% 0;
    /* animation:scroll1 2.5s ease-out infinite;  */
    margin-top: 10px;
  }

  @keyframes scroll1{
    0%{background-size:10%}
    5%{background-size:10%}
    20%{background-size:10%}
    40%{background-size:10%}
    60%{background-size:10%}
    80%{background-size:10%}
    100%{background-size:10%}
    100%{background-position:125%,0;}
  }



/* mobile view */
@media only screen and (max-width:992px){
  .techdesk-container h1 {
    color: var(--primary-color);
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    /* text-transform: uppercase; */
    /* text-transform: capitalize; */
    font-size: 3.0rem;
  }
  .techdesk-container h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.0rem;
    color: var(--primary-color);
    text-align: left;
    /* line-height: 32px; */
    padding-bottom: 10px;
  }
  .techdesk-container h1 {
    color: var(--primary-color);
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    /* text-transform: uppercase; */
    /* text-transform: capitalize; */
    font-size: 24px;
}


}
	
  