@media only screen and (min-width:1200px){
	.nav-item > .nav-link{padding: 5px 20px !important;
		display: block !important;}
}

@media only screen and (max-width:992px){
	.header-inner{background-color: white!important;
	}
	/* .nav-item > .nav-link{
		color: black!important}
		.logo{color: #000 !important;
			font-weight: 600!important;}
			.content-banner .first-title{font-size: 30px !important;
			} */
}

.banner-btn{padding:0 34px;
z-index: 1;
margin:30px auto 25px;
line-height: 56px;
position: relative;
border-radius: 6px;
height:56px;
font-size: 16px;
background-color: #066ac9;
display: inline-block;
color: #ffffff;
transition: 0.3s;
text-align: center;

}


.header{position: relative;
width: 100%;
}

.header-inner{
position: absolute;
top: 0;
left: 0;
width:100%;
z-index: 99;
background-color: transparent;
}

.nav-item .nav-link
{display: block;
line-height: 36px;
text-transform: capitalize;
font-size: 16px;
text-align: center;
font-weight: 500;
color: #000;
transition: 0.15s;}

.nav-item .nav-link:hover{color: #1e295d;}

.header-btn{color:#ffffff;
border-radius: 6px;
background-color:#066ac9;
border:none;
font-weight: 500;
outline: none;
font-size: 15px;
padding:7px 22px;
transition: 0.5s;
}

.header-btn:hover{background-color: #1e295d;
cursor: pointer;}

.navbar-scroll{background-color: #ffffff;
padding: 0;
position: fixed;
top:0;
z-index: 99;

box-shadow:0px 1px 10px rgba(0,0,0,0.4);
transition-duration: 0.6s; }

.navbar-scroll .nav-item .nav-link{color: black;
}

.navbar-scroll .header-btn{color: #ffffff;
background-color: #066ac9;
}

.navbar-scroll .logo{color: #000;
font-weight: 500;
}

.first-title{
	text-transform: capitalize;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.navbar-toggler{
	outline: none;
}