.techdesk-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 40px;
  }
   .techdesk-container h2
   {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.8125rem;
    color: var(--primary-color);
    text-align: left;
   }
   .techdesk-container h1
   {
    color: var(--primary-color);
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    /* text-transform: uppercase; */
    /* text-transform: capitalize; */
    font-size: 4.6rem;
   }
  
  
   .techdesk-para{
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 3rem 0 3rem 0;
    font-weight: lighter;
    text-align: left;
   }
  

   .google-maps {
    height: 450px;
    overflow: hidden;
  }
  .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  
  /* --------------------------------- Contact US Section starts  
                          -------------------------------------------------------*/
 .techdesk-title2 {
    margin-bottom: 2rem;
}
  .techdesk-result
  {
    -webkit-appearance: none;
    height: 5rem;
    border-radius: 0.5rem;
    font-size: 2.6rem;
    padding-left: 1rem;
    font-weight: 400;
color: #066ac9;

  }
  .contact-input-feild1 {
    margin-bottom: 2rem;
  }
  
  .contact-rightside .form-control {
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    height: 5rem;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    padding-left: 1rem;
    font-weight: lighter;
  }
  
  .contact-rightside .form-check-input {
    width: 2rem;
    height: 2rem;
  }
  
  .form-checkbox-style {
    margin: 4rem 0;
  }
  
  .contact-rightside form .main-hero-para {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 0;
    font-weight: lighter;
    width: 75%;
    padding: 0 2rem;
  }
  /* --------------------------------- Contact US Section ends  
                                                    -------------------------------------------------------*/
  .subtitle_contact {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 3rem 0 3rem 0;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
  }
  .contact-container {
    justify-content: left;
    align-items: left;
  }
  .contact-container h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.8125rem;
    color: var(--primary-color);
    text-align: left;
  }
  
  .contact-para {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 3rem 0 3rem 0;
    font-weight: lighter;
    text-align: left;
  }
  
  
  #services .icon1 {
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 200px;
    height: 100px;
    color: #066ac9;
  }
  
  #services .icon1 .service-icon {
    font-size: 46px;
    line-height: 1;
  }
  
  @media only screen and (max-width:992px){
  
    .contact-container h2 {
      text-transform: capitalize;
      font-weight: 700;
      font-size: 2.0rem;
      color: var(--primary-color);
      text-align: left;
  }
    }
  
  
    .btn-style {
      color: #ffffff !important;
      border-radius: 25px;
      background-color: #066ac9;
      border: none;
      font-weight: 500;
      outline: none;
      font-size: 15px;
      padding: 7px 22px;  
      line-height: 30px !important;
    }
  
  
    #services .description a{
  text-decoration: none;
  }

  .calbox{
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #e5e9ff;
    box-shadow: 0 10px 29px 0 rgb(68 88 144 / 10%);
    transition: all 0.3s ease-in-out;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: #dceaff;
    opacity: 1;
}

.table{
  border-color: rgb(13 110 253 / 25%);
}


.table th, .table td{
 font-size: 14px;
 padding-top: 20px;
 padding-bottom: 20px;
text-align: center;
}


#ex1Slider {
  width: 80%;

}
#ex1Slider input{
color: #066ac9;  
}

.tooltip.in {
  opacity: 1;
}

/* .table > :not(:first-child) {
  border-top: 0;
} */

.table > :not(:first-child) {
  border-top: 2px solid #066ac9;
}



