.testimonials-clean {
  color: #333333;
  background-color: var(--bgcolor);
}

.testimonials-clean p {
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: lighter;
}

.testimonials-clean h2 {
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 3em;
  text-align: center;
  color: var(--primary-color);
}

@media (max-width: 767px) {
  .testimonials-clean h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.testimonials-clean .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.testimonials-clean .intro p {
  margin-bottom: 0;
}

.testimonials-clean .people {
  padding: 50px 0 20px;
}

@media (min-width: 768px) {
  .testimonials-clean .item {
    /* height:220px; */
  }
}

.testimonials-clean .item .box {
  padding: 30px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
}

.testimonials-clean .item .box:after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -24px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-width: 12px 15px;
  border-top-color: #fff;
}

.testimonials-clean .item .author {
  margin-top: 28px;
  padding-left: 25px;
}

.testimonials-clean .item .name {
  font-weight: bold;
  margin-bottom: 2px;
  color: inherit;
}

.testimonials-clean .item .title {
  font-size: 13px;
  color: #9da9ae;
}

.testimonials-clean .item .description {
  font-size: 15px;
  margin-bottom: 0;
}

.testimonials-clean .item img {
  max-width: 40px;
  float: left;
  margin-right: 12px;
  margin-top: -5px;
}

.header-btn1 {
  background-color: #066ac9;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 500;
  outline: none;
  padding: 7px 17px;
  transition: .5s;
}

.header-btn1:hover {
  background-color: #1e295d;
  cursor: pointer;
}
/* news section */
.news {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #f1f5fd;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
}

.carousel {
  margin: 0 !important;
}

.header3 {
  background-color: var(--bgcolor);
}

.news-heading p {
	font-size: 1.5rem;
    color: var(--text-color);
    font-weight: lighter;
    text-align: left;
	margin-top: 0;
    margin-bottom: 0;
   
}
.news-heading p a{
	font-size: 1.5rem;
    color: #000000;
    font-weight: lighter;
    text-align: left;
    height: 100%;
    text-decoration: none;

}

.news-heading p a:hover{

    color: #0a58ca;

}



.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%28F00' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%28F00' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-caption {
    position: absolute;
    right: 10%;
    bottom: 0rem;
    left: 10%;
    padding-top:0rem;
    padding-bottom: 0rem;
    color: #fff;
    text-align: center;
    height: 100%;
}



    /* Carousel*/
    .carousel,
    .carousel-item,
    .carousel-item.active {
      height: 100%;
    }
    .carousel-inner {
      height: 100%;
    }
  .carousel-caption{
    color: #000;
 
  }


  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
    height: 10px !important;
    width: 10px !important;
}

.carousel-caption {
    top: 0;
    bottom: auto;
}

   .news-title h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.8125rem;
    color: var(--primary-color);
    text-align: left;
    height: 100%;
}


/* .carousel-inner > .item > img {
  min-width: 100%;
  width: 100%;
} */

@media only screen and (max-width:992px){
  .news-heading p a{
    font-size: 1.5rem;
      color:#000;
      font-weight: lighter;
      text-align: left;
      height: 100%;
      text-decoration: none;
  
  }
  .header-btn1 {
    background-color: #066ac9;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 500;
    outline: none;
    padding: 7px 17px;
    transition: .5s;
  }

  .news-title h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--primary-color);
    text-align: left;
    height: 100%;
}
}


  @keyframes changeLetter {
    0% { content:"Groceries";}
    10% { content:"Home Accessories";}
    20% { content:"Dairy Products";}
    30% { content:"Food";}
    40% { content:"Groceries"}
    50% { content: "Home Accessories"}
    60% { content: "Dairy Products"}
    70% { content: "Food"}
    80% { content: "Groceries"}
    90% { content: "Home Accessories"}
    100% { content: "Dairy Products"}
  }
  
  @-webkit-keyframes changeLetter {
    0% { content:"Groceries";}
    10% { content:"Home Accessories";}
    20% { content:"Dairy Products";}
    30% { content:"Food";}
    40% { content:"Groceries"}
    50% { content: "Home Accessories"}
    60% { content: "Dairy Products"}
    70% { content: "Food"}
    80% { content: "Groceries"}
    90% { content: "Home Accessories"}
    100% { content: "Dairy Products"}
  }
  
  @-ms-keyframes changeLetter {
    0% { content:"Groceries";}
    10% { content:"Fruits";}
    20% { content:"Home Accessories";}
    30% { content:"Dairy Products";}
    40% { content:"Groceries"}
    50% { content: "Fruits"}
    60% { content: "Home Accessories"}
    70% { content: "Dairy Products"}
    80% { content: "Groceries"}
    90% { content: "Fruits"}
    100% { content: "Home Accessories"}
  }
  

  .element {
    display: flex;
    align-items: left;
    justify-content: left;
  }
    .element:after {
      animation: changeLetter fadein  15s linear infinite;
      -ms-animation: changeLetter  fadein 15s linear infinite;
      -webkit-animation: changeLetter 15s linear infinite;
      display: block;
      content: "Dairy Products";
      color: var(--primary-color);
      text-align: left;
      margin-bottom: 0.5rem;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-size: 4.6rem;
    }
  

    /* mobile view */
@media only screen and (max-width:992px){
  .element {
    display: flex;
    align-items: left;
    justify-content: left;
  }
    .element:after {
      animation: changeLetter fadein  15s linear infinite;
      -ms-animation: changeLetter  fadein 15s linear infinite;
      -webkit-animation: changeLetter 15s linear infinite;
      display: block;
      content: "Dairy Products";
      color: var(--primary-color);
      text-align: left;
      margin-bottom: 0.5rem;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-size: 24px;
    }
  

}
	

.techdesk-para a{
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: lighter;
  text-align: left;
  text-decoration: none;
}

.techdesk-para span {
  font-weight: 550;
  color: #000;
}

.item:hover{
  filter: brightness(0.7) !important;
}