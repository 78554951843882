.google-maps {
  height: 450px;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* --------------------------------- Contact US Section starts  
                        -------------------------------------------------------*/

.contact-input-feild {
  margin-bottom: 4rem;
}

.contact-rightside form .form-control {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  height: 5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 2rem;
  height: 2rem;
}

.form-checkbox-style {
  margin: 4rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
  font-weight: lighter;
  width: 75%;
  padding: 0 2rem;
}
/* --------------------------------- Contact US Section ends  
                                                  -------------------------------------------------------*/
.subtitle_contact {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}
.contact-container {
  justify-content: left;
  align-items: left;
}
.contact-container h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 2.8125rem;
  color: var(--primary-color);
  text-align: left;
}

.contact-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  font-weight: lighter;
  text-align: left;
}


#services .icon1 {
  padding-top: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 200px;
  height: 100px;
  color: #066ac9;
}

#services .icon1 .service-icon {
  font-size: 46px;
  line-height: 1;
}

@media only screen and (max-width:992px){

  .contact-container h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.0rem;
    color: var(--primary-color);
    text-align: left;
}
  }


  .btn-style {
    color: #ffffff !important;
    border-radius: 25px;
    background-color: #066ac9;
    border: none;
    font-weight: 500;
    outline: none;
    font-size: 15px;
    padding: 7px 22px;  
    line-height: 30px !important;
  }


  #services .description a{
text-decoration: none;
}