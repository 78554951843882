.section-bg {
    background: #f5f8fd;
  }
  
  .section-header h3 {
    font-size: 36px;
    color: #413e66;
    text-align: center;
    font-weight: 700;
    position: relative;
    font-family: "Raleway", sans-serif;
  }
  
  .section-header p {
    text-align: center;
    margin: auto;
    font-size: 15px;
    padding-bottom: 60px;
    color: #535074;
    width: 50%;
  }
  
  @media (max-width: 767px) {
    .section-header p {
      width: 100%;
    }
  }
  
  #services .box {
    padding: 25px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 10px 10px 10px 10px;
    background: #e5e9ff;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
  #services .box:hover {
    transform: scale(1.1);
  }
  

  @media (pointer:coarse) {
    #services .box:hover {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }
    }
  #services .icon {
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    color: #066ac9;
  }
  
  #services .icon .service-icon {
    font-size: 46px;
    line-height: 1;
  }

  #services .title {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 2.8125rem;
  }

  #services .title a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  #services .box:hover .title a {
    text-decoration: none;
  }
  #services .box:hover .title a:hover {
    text-decoration: none;
  }
  #services .title1 {
    font-weight: 700;
    font-size: 18px;
  }
  
  #services .title1 a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  #services .box:hover .title1 a {
    text-decoration: none;
  }
  #services .box:hover .title1 a:hover {
    text-decoration: none;
  }


  #services .description {
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
    color: #5e6c84;
  }




 .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.325rem;
}

/* List css */

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 0rem;
  color: var(--text-color);
  text-decoration: none;
  background-color: transparent;
  border: 0px solid rgba(0,0,0,.125);
}

.text-success {
  color: #198754!important;
}

.me-2 {
  margin-right: 0.5rem!important;
}

.list-para{
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: lighter;
  text-align: left;
}


