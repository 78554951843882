.boldword{
    font-weight: 700;
}

  
  @media (max-width: 767px) {
    .section-header p {
      width: 100%;
    }
  }
  
  #services1 .box {
    padding: 15px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px 0px 5px 0px;
    background: #e5e9ff;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
  #services1 .box:hover {
    transform: scale(1.1);
  }
  

  @media (pointer:coarse) {
    #services1 .box:hover {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }
    }
  #services1 .icon {
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    color: #066ac9;
  }
  
  #services1 .icon .service-icon {
    font-size: 46px;
    line-height: 1;
  }

  #services1 .title {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 2.8125rem;
  }

  #services1 .title a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  #services1 .box:hover .title a {
    text-decoration: none;
  }
  #services1 .box:hover .title a:hover {
    text-decoration: none;
  }
  #services1 .title1 {
    font-weight: 700;
    font-size: 18px;
  }
  
  #services1 .title1 a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  #services1 .box:hover .title1 a {
    text-decoration: none;
  }
  #services1 .box:hover .title1 a:hover {
    text-decoration: none;
  }


  #services1 .description {
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
    color: #5e6c84;
  }


/* faq */

#accordionFlushExample{
  font-size: 15px;
    margin-bottom: 0;
    color: #5e6c84;
}
.accordion-button{
  font-size: 15px;
    margin-bottom: 0;
    color: #5e6c84;
    font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #e5e9ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  background-color: #e5e9ff;
}

.accordion-flush .accordion-item {
  margin-bottom: 10px;
}


.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  border-top: 5px solid #e5e9ff;
  border-bottom: 5px solid #e5e9ff;
  border-left: 5px solid #e5e9ff;
  border-right: 5px solid #e5e9ff;
  border-radius: 14px;
  background-color: #e5e9ff;
}

.accordion-button:not(.collapsed) {

  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
}

.accordion-item {
  background-color: rgba(255, 255, 255, 0);
  border: 0px solid rgba(0,0,0,.125);
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
 
  background-color: #066ac9;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  padding: 7px 17px;
}